body {
  font-family: monospace;
  color: black;
}

svg {
  fill: black !important;
}

.main-heading {
  font-weight: bold;
  font-size: 3rem;
  padding-left: 12px;
  position: fixed;
  color: white;
  -webkit-text-stroke: 2px black;
}

.contact-heading {
  font-size: 2rem;
  padding-right: 16px;
  position: fixed;
  bottom: 0;
  right: 0;
  color: white;
  -webkit-text-stroke: 1.5px black;
}

@media screen and (max-width: 600px) {
  .main-heading {
    font-size: 2rem;
  }

  .contact-heading {
    font-size: 1.5rem;
    -webkit-text-stroke: 1px black;
  }
}

.react-images__header_button--fullscreen {
  display: none !important;
}

.react-images__header,
.react-images__footer {
  background-image: none !important;
  color: black !important;
}

.react-images__blanket {
  background-color: white !important;
}
